.description {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 100px;
	padding-bottom: 80px;
	width: 100%;

	.description-text {
		width: 400px;
		color: rgba(45, 45, 45, 1);

		p {
			line-height: 1.6em;

			&:last-of-type {
				padding-top: 16px;
			}
		}

		@media (prefers-color-scheme: dark) {
			color: rgba(210, 210, 210, 1);
		}
	}
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	@media (prefers-color-scheme: dark) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);

		path {
			fill: none;
		}
	}

	@media screen and (max-width: 720px) {
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
		text-align: center;

		.description-text {
			width: 100%;
			padding: 30px;
			margin: 0;

			p:last-of-type {
				padding-top: 32px;
			}
		}
	}
}
