$footer-color: #666;

#footer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f6f6f6;
	padding: 12px 0px;
	font-size: 9pt;
	color: $footer-color;

	@media (prefers-color-scheme: dark) {
		background: #161616;
		color: rgba(255, 255, 255, 0.5);
	}

	.container {
		.withSidePadding {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width: 480px) {
				flex-direction: column;
				align-items: center;
				justify-content: center;

				ul {
					flex-direction: column;
					text-align: center;
					li {
						margin: 12px 0px;

						&:last-of-type {
							margin-bottom: 0px;
						}
					}
				}
			}
		}
	}

	ul {
		list-style-type: none;
		display: flex;
		margin-block-start: 0em;
		padding-inline-start: 0px;
		padding-top: 10px;

		li {
			margin: 0px 10px;

			a {
				text-decoration: none;
				color: $footer-color;

				@media (prefers-color-scheme: dark) {
					color: rgba(255, 255, 255, 0.5);
				}
			}
		}
	}
}
