#clients {
	margin-top: 12px;
	padding-bottom: 80px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (prefers-color-scheme: dark) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	svg {
		margin: 40px 0px 60px;

		@media (prefers-color-scheme: dark) {
			path {
				fill: white;
			}
		}
	}
}
