#page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	#content {
		display: flex;
		margin-top: 100px;
	}
}

.withSidePadding {
	width: 100%;
}
