@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(179deg);
	}
}

@keyframes rotation-one {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(120deg);
	}
}

@keyframes rotation-two {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(60deg);
	}
}

@keyframes rotation-three {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(150deg);
	}
}

@keyframes rotation-four {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(90deg);
	}
}

@keyframes rotation-five {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(30deg);
	}
}

@keyframes solidify {
	from {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}

#hero {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 100px;
	#hero-text {
		width: 400px;
	}
	svg#ident {
		animation: solidify 2s ease-in-out forwards;
		animation-delay: 4s;
		opacity: 0.5;

		#ellipse-original {
			animation: rotation 4s ease-in-out forwards;
			transform-origin: center;
		}

		#ellipse-0 {
			animation: rotation-one 4s ease-in-out forwards;
			transform-origin: center;
		}

		#ellipse-1 {
			animation: rotation-two 4s ease-in-out forwards;
			transform-origin: center;
		}
		#ellipse-2 {
			animation: rotation-three 4s ease-in-out forwards;
			transform-origin: center;
		}
		#ellipse-3 {
			animation: rotation-four 4s ease-in-out forwards;
			transform-origin: center;
		}
		#ellipse-4 {
			animation: rotation-five 4s ease-in-out forwards;
			transform-origin: center;
		}
	}

	padding-bottom: 120px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	@media (prefers-color-scheme: dark) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	@media screen and (max-width: 480px) {
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;

		svg#ident,
		#hero-text {
			margin: 0;
		}

		#hero-text {
			text-align: center;
			width: 100%;
			padding-top: 24px;
		}
	}
}
